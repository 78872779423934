import React from "react"
import Modal from "react-modal"
import styled from "styled-components"
import tw from "twin.macro"

Modal.setAppElement("#___gatsby")

const CustomModal = ({ className, ...props }) => {
  const contentClassName = `${className}__content`
  const overlayClassName = `${className}__overlay`
  return (
    <Modal
      {...props}
      className={contentClassName}
      overlayClassName={overlayClassName}
    />
  )
}

const StyledModal = styled(CustomModal)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0`}

    background-color: rgba(0, 0, 0, .8);
    z-index: 2000;

    & > .mainHeroModal__content {
      ${tw`xl:mx-auto m-4 sm:m-3 max-w-screen-xl absolute inset-0 flex flex-col-reverse
      justify-center items-center rounded-lg bg-transparent outline-none`}

      // If modal is running on Cookie Policy page.
      ${props => props.cookieModal && tw`max-w-screen-md max-h-full lg:h-2/3 m-0 p-4 flex-col justify-start`}
    }
  }
`

export default StyledModal
