import React, { useState } from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"

import CustomModal from "../../components/Common/CustomModal"
import { getCookie, setCookie } from "../../helpers/cookie"
import { GA_COOKIE_NAME } from "../../constants/analytics"
import { useEffect } from "react"

const ModalContainer = styled.div`
  ${tw`flex flex-col justify-between items-center w-full max-h-full
   rounded-lg h-full outline-none`}
`

const Menu = styled.div`
  ${tw`w-full flex flex-row flex-wrap p-2 items-center justify-evenly rounded-lg outline-none
    bg-gray-800`}

  button {
    ${tw`m-2 text-gradient font-semibold border-b-2 border-transparent text-left outline-none
    hocus:border-gradient pb-1 transition[all] duration-300 cursor-pointer lg:m-4 hocus:outline-none`}
  }
`

const Content = styled.div`
  ${tw`w-full max-h-screen h-full p-4 pt-0 overflow-auto bg-gray-100 lg:p-5`}

  h1 {
    ${tw`text-2xl font-bold my-3`}
  }

  p {
    ${tw`text-base leading-normal`}
  }

  .title {
    ${tw`flex flex-row justify-between items-center`}

    /* Toggle B */
    & >input:checked ~ .dot {
      transform: translateX(100%);
      background-color: #48bb78;
    }

    .switch-button {
      width: 2.8rem;
    }
  }
`

const Footer = styled.div`
  ${tw`flex flex-col lg:flex-row justify-end p-2 rounded-b-lg bg-gray-100 w-full`}
`

const FooterButton = styled.button`
  ${tw`text-center inline-block w-full p-2 my-1 lg:m-2 lg:p-4 font-semibold tracking-wide rounded-lg 
    cursor-pointer transition[all] duration-300`}
  ${tw`bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800 hocus:outline-none outline-none`}
    ${props => props.green && tw`bg-green-300 hover:bg-green-400`}
`

export default function CookiesSettings({ visible, toggleModal }) {
  const [selectedSetting, setSelectedSetting] = useState("your-privacy")
  const [settings, setSettings] = useState(null)
  const { t, i18n } = useTranslation()
  const { language } = i18n

  const data = useStaticQuery(graphql`
    query($content: String = "cookies-settings") {
      allMarkdownRemark(
        filter: { frontmatter: { content: { eq: $content } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              lang
              title
              heading
            }
            html
          }
        }
      }
    }
  `)

  const filteredEdge = data.allMarkdownRemark.edges.find(
    edge =>
      edge.node.frontmatter.lang === language &&
      edge.node.frontmatter.title === selectedSetting
  )

  const { heading } = filteredEdge.node.frontmatter
  const { html } = filteredEdge.node

  useEffect(() => {
    const isCookieActive = getCookie(GA_COOKIE_NAME) === "true" ? true : false

    if (isCookieActive) {
      setSettings({
        "analytics-cookies": true,
        "advertising-cookies": true,
      })
    } else {
      setSettings({
        "analytics-cookies": false,
        "advertising-cookies": false,
      })
    }
  }, [])

  const toggleSettings = () => {
    setSettings(state => ({
      ...state,
      [selectedSetting]: !state[selectedSetting],
    }))
  }

  const acceptAllCookies = () => {
    setCookie(GA_COOKIE_NAME, true, 10000)
    setSettings({
      "analytics-cookies": true,
      "advertising-cookies": true,
    })
    toggleModal()
  }

  const saveAndClose = () => {
    if (settings["analytics-cookies"]) {
      setCookie(GA_COOKIE_NAME, true, 10000)
    } else {
      setCookie(GA_COOKIE_NAME, false, 10000)
    }
    toggleModal()
  }

  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      toggleSettings()
    }
  }

  return (
    <CustomModal
      closeTimeoutMS={300}
      className="mainHeroModal"
      isOpen={visible}
      onRequestClose={toggleModal}
      shouldCloseOnOverlayClick={true}
      cookieModal={true}
    >
      <ModalContainer>
        <Menu>
          <button onClick={() => setSelectedSetting("your-privacy")}>
            {t("COOKIES_MODAL_PRIVACY")}
          </button>
          <button onClick={() => setSelectedSetting("essential-cookies")}>
            {t("COOKIES_MODAL_ESSENTIAL")}
          </button>
          <button onClick={() => setSelectedSetting("analytics-cookies")}>
            {t("COOKIES_MODAL_ANALYTICS")}
          </button>
          <button onClick={() => setSelectedSetting("advertising-cookies")}>
            {t("COOKIES_MODAL_ADVERTISING")}
          </button>
        </Menu>
        <Content>
          <div className="title">
            <h1>{heading}</h1>
            {/* Toggle Button */}
            {selectedSetting === "essential-cookies" && (
              <p tw="text-green-600 font-semibold">
                {t("COOKIES_MODAL_ALWAYS")}
              </p>
            )}
            {(selectedSetting === "analytics-cookies" ||
              selectedSetting === "advertising-cookies") && (
              <div
                role="button"
                className="flex justify-between items-center cursor-pointer"
                onClick={toggleSettings}
                onKeyDown={handleKeyPress}
                tabIndex={0}
              >
                <div
                  className={`switch-button h-6 flex items-center bg-gray-300 rounded-full duration-200 ease-in 
                ${settings[selectedSetting] && "bg-green-300"}
                `}
                >
                  <div
                    className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-200 ease-in
                  ${settings[selectedSetting] && "translate-x-5"}
                  `}
                  ></div>
                </div>
              </div>
            )}
            {/* Toggle Button - Finish */}
          </div>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Content>
        <Footer>
          <FooterButton green={true} onClick={acceptAllCookies}>
            {t("COOKIES_MODAL_ACCEPT_ALL")}
          </FooterButton>
          <FooterButton onClick={saveAndClose}>
            {t("COOKIES_MODAL_SAVE_CLOSE")}
          </FooterButton>
        </Footer>
      </ModalContainer>
    </CustomModal>
  )
}
