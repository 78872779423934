import * as React from "react"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import tw from "twin.macro"
import styled from "@emotion/styled"

import Seo from "../components/SEO"
import {
  Container,
  ContentWithPaddingXl,
} from "../components/Common/Containers"
import { PolicyText } from "../components/Common/PolicyText"
import { AltHeading } from "../components/Common/Headings"
import ScrollToTop from "../components/Common/ScrollToTop"
import CookiesSettings from "../components/CookiesSettings"

const CookieSettings = styled.div`
  ${tw`p-6 rounded-2xl 
  border-2 border-dashed border-gradient lg:block lg:text-left
  text-gradient font-semibold border-b-2 
  hocus:border-gradient transition[all] duration-300
  `}

  button {
    ${tw`text-gradient font-semibold border-b-2 border-transparent outline-none
    hocus:border-gradient pb-1 transition[all] duration-300 cursor-pointer`}
  }
`

const Cookies = ({ path }) => {
  const [visible, setVisible] = React.useState(false)

  const { t, i18n } = useTranslation()
  const { language } = i18n

  const data = useStaticQuery(graphql`
    query($content: String = "cookies") {
      allMarkdownRemark(
        filter: { frontmatter: { content: { eq: $content } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              lang
              heading
            }
            html
          }
        }
      }
    }
  `)

  const toggleModal = () => setVisible(state => !state)

  const filteredEdge = data.allMarkdownRemark.edges.find(
    edge => edge.node.frontmatter.lang === language
  )

  const { heading } = filteredEdge.node.frontmatter
  const { html } = filteredEdge.node

  return (
    <>
      <Seo title={heading} />
      <Container id="cookies">
        <ScrollToTop />
        <ContentWithPaddingXl>
          <AltHeading>{heading}</AltHeading>
          <CookieSettings>
            <button onClick={toggleModal}>{t("COOKIES_CHANGE_TEXT")}</button>
          </CookieSettings>
          <PolicyText dangerouslySetInnerHTML={{ __html: html }} />
        </ContentWithPaddingXl>
        <CookiesSettings visible={visible} toggleModal={toggleModal} />
      </Container>
    </>
  )
}

export default Cookies
